@import './main.Scss';

.about-section{
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr 1fr 10fr 1fr 1fr;
    color: $primary;
    font-weight: 400;
    min-height: 100vh;
}

.about-name{
    margin-top: 120px;
    width: 400px;
    height: 200px;
    align-self: flex-end;
    justify-self: center;
    text-align: center;
    grid-column: 3;
    font-size: 60px;
    text-shadow: 0px 3px 6px rgba(0,0,0,0.3);
    .about-name-light{
        font-weight: 500;
        font-size: 80px;
        color: $secondary;
    }
    @media (max-width: $medium) {
        font-size: 50px;
        height: 170px;
        .about-name-light{
            font-size: 70px;
        }        
    }
    @media (max-width: $small){
        font-size: 40px;
        height: 130px;
        margin-top: 80px;
        .about-name-light{
            font-size: 60px;
        }
    }
    @media (max-width: $smartphone){
        font-size: 33px;
        width: 100%;
        height: 110px;
        .about-name-light{
            font-size: 45px;
        }
    }
}

.about-desc{
    grid-column: 3;
    color: $secondary;
    font-size: 40px;
    text-align: center;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 100px;
    
    @media (max-width: $medium) {
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 50px;
        .about-desc-separator{
            visibility: hidden;
            &::before{
                content: "\a";
                white-space:pre;
            }
            &:first-child{
                margin-left: -20px;
            }
        }
    }
    @media (max-width: $smartphone) {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 45px;
    }
}

.about-check{
    grid-column: 3;
    color: $light;
    font-weight: 500;
    font-size: 90px;
    text-align: center;
    text-shadow: 0px 3px 6px rgba(0,0,0,0.4);
    @media (max-width: $medium) {
        font-size: 70px;        
    }
    @media (max-width: $small) {
        font-size: 55px;        
    }
    @media (max-width: $smartphone){
        font-size: 40px;
    }
}

.about-arrow{
    grid-column: 3;
    justify-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
    filter: drop-shadow(0px 3px 6px rgba(0,0,0,0.2));
    @media (max-width: $medium) {
        height: 130px;        
    }
    @media (max-width: $small) {
        margin-top: 0px;
        margin-bottom: 10px;
        height: 100px;
    }
}

.dash-2{
    grid-column: 3;
    width: 400px;
    height: 10px;
    @media (max-width: $small) {
        width: 80%;       
    }
    @media (max-width: $smartphone) {
        width: 220px;
    }
}

.dash-vertical{
    width: 10px;
    height: 200px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
}

.dash-3{
    grid-row: 1;
    align-self: flex-end;
    height: 200px;
    
    @media (max-width: $smartphone) {
        height: 150px;
    }
}

.dash-4{
    grid-row: 1;
    grid-column: 5;
    align-self: center;
    height: 150px;
    @media (max-width: $smartphone) {
        height: 120px;
    }
}

.dash-5{
    grid-row: 4;
    grid-column: 2;
    @media (max-width: $smartphone) {
        height: 100px;
    }
}

.dash-6{
    grid-row: 4;
    grid-column: 4;
    height: 150px;
    margin-top: 20px;
    @media (max-width: $smartphone) {
        height: 100px;
    }
}

.dash-7{
    grid-row:5;
    grid-column:1;
    align-self: flex-end;
    height: 300px;
    margin-bottom: -150px;
    @media (max-width: $smartphone) {
        height: 200px;
        margin-bottom: -100px;
    }
}

.dash-8{
    grid-column: 5;
    align-self: flex-end;
    height: 250px;
    margin-bottom: -80px;
    @media (max-width: $smartphone) {
        height: 150px;
        margin-bottom: -60px;
    }
}