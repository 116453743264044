@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #546663;
  font-family: 'Roboto', sans-serif;
}

.main-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1400px 1fr;
      grid-template-columns: 1fr 1400px 1fr;
}

@media (max-width: 1450px) {
  .main-container {
    -ms-grid-columns: 1fr 800px 1fr;
        grid-template-columns: 1fr 800px 1fr;
  }
}

@media (max-width: 800px) {
  .main-container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.dash {
  background-color: #5AE6CE;
  -ms-grid-column-align: center;
      justify-self: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.landing-wrapper {
  background-color: #00AD90;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1/4;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1400px 1fr;
      grid-template-columns: 1fr 1400px 1fr;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  min-height: 750px;
}

@media (max-width: 1450px) {
  .landing-wrapper {
    -ms-grid-columns: 1fr 800px 1fr;
        grid-template-columns: 1fr 800px 1fr;
  }
}

@media (max-width: 800px) {
  .landing-wrapper {
    min-height: 600px;
    -ms-grid-columns: 1fr 500px 1fr;
        grid-template-columns: 1fr 500px 1fr;
  }
}

@media (max-width: 500px) {
  .landing-wrapper {
    -ms-grid-columns: 1fr 300px 1fr;
        grid-template-columns: 1fr 300px 1fr;
  }
}

.landing-section {
  -ms-grid-column: 2;
  grid-column: 2;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (100px)[4] 600px (100px)[4];
      grid-template-columns: repeat(4, 100px) 600px repeat(4, 100px);
  width: 100%;
  min-height: 100vh;
}

@media (max-width: 1450px) {
  .landing-section {
    -ms-grid-columns: (50px)[4] 400px (50px)[4];
        grid-template-columns: repeat(4, 50px) 400px repeat(4, 50px);
  }
}

@media (max-width: 800px) {
  .landing-section {
    -ms-grid-rows: 320px;
        grid-template-rows: 320px;
    -ms-grid-columns: 20px 1fr 2fr 1fr 20px;
        grid-template-columns: 20px 1fr 2fr 1fr 20px;
    gap: 10px;
  }
}

@media (max-width: 500px) {
  .landing-section {
    -ms-grid-columns: 10px 1fr 1.5fr 1fr 10px;
        grid-template-columns: 10px 1fr 1.5fr 1fr 10px;
    gap: 5px;
  }
}

.icon-bar {
  position: absolute;
  right: 20px;
  top: 30px;
  height: 50px;
  z-index: 3;
}

.icon-bar img {
  height: 100%;
  margin-right: 10px;
}

@media (max-width: 800px) {
  .icon-bar {
    height: 40px;
  }
  .icon-bar img {
    margin-right: 8px;
  }
}

.logo {
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-grid-row: 5;
  grid-row: 5;
  -ms-grid-column-align: center;
      justify-self: center;
  position: relative;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.logo .logo-back {
  position: absolute;
  top: -110px;
  left: -20px;
  z-index: 1;
  width: 100%;
}

.logo .logo-front {
  position: relative;
  z-index: 2;
  width: 100%;
}

.logo::before {
  content: '<webdev />';
  position: absolute;
  top: 160px;
  color: #006151;
  font-size: 110px;
  font-weight: 300;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

@media (max-width: 1450px) {
  .logo .logo-back {
    top: -75px;
  }
  .logo::before {
    font-size: 79px;
    top: 120px;
  }
}

@media (max-width: 800px) {
  .logo {
    margin-top: 120px;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    width: 300px;
  }
  .logo .logo-back {
    top: -60px;
  }
  .logo::before {
    top: 85px;
    font-size: 59px;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 280px;
  }
  .logo .logo-back {
    top: -50px;
  }
  .logo::before {
    top: 70px;
    font-size: 55px;
  }
}

.skill {
  position: relative;
  color: #5AE6CE;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 5px;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 4;
}

@media (max-width: 1450px) {
  .skill {
    font-size: 40px;
  }
}

@media (max-width: 800px) {
  .skill {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .skill {
    font-size: 22px;
    letter-spacing: 2px;
  }
}

.skill-html {
  -ms-grid-column: 4;
  grid-column: 4;
  -ms-grid-row: 1;
  grid-row: 1;
  justify-self: flex-start;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.skill-html::after {
  content: "HTML";
  position: absolute;
  display: block;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}

@media (max-width: 800px) {
  .skill-html::after {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}

@media (max-width: 800px) {
  .skill-html {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
    justify-self: flex-end;
    left: 15px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
}

.skill-js {
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column-align: center;
      justify-self: center;
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.skill-js::after {
  content: "JavaScript";
  position: absolute;
  display: block;
  top: -50px;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}

@media (max-width: 800px) {
  .skill-js::after {
    -webkit-transform: rotate(17deg);
            transform: rotate(17deg);
  }
}

@media (max-width: 800px) {
  .skill-js {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 3;
    grid-row: 3;
    justify-self: flex-start;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
}

.skill-git {
  -ms-grid-column: 9;
  grid-column: 9;
  -ms-grid-row: 2;
  grid-row: 2;
  justify-self: flex-start;
}

.skill-git::after {
  content: "GIT";
  position: absolute;
  display: block;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}

@media (max-width: 800px) {
  .skill-git::after {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
}

@media (max-width: 1450px) {
  .skill-git {
    -ms-grid-column: 7;
    grid-column: 7;
    -ms-grid-row: 2;
    grid-row: 2;
    justify-self: flex-start;
  }
}

@media (max-width: 800px) {
  .skill-git {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 5;
    grid-row: 5;
    justify-self: flex-end;
    -ms-flex-item-align: start;
        align-self: flex-start;
    top: 15px;
  }
}

.skill-gsap {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 4;
  grid-row: 4;
  justify-self: flex-start;
}

.skill-gsap::after {
  content: "GSAP";
  position: absolute;
  display: block;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

@media (max-width: 800px) {
  .skill-gsap::after {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@media (max-width: 800px) {
  .skill-gsap {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 2;
    grid-row: 2;
    justify-self: flex-start;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
}

.skill-es6 {
  -ms-grid-column: 8;
  grid-column: 8;
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column-align: 10deg;
      justify-self: 10deg;
}

.skill-es6::after {
  content: "ES6";
  position: absolute;
  display: block;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}

@media (max-width: 800px) {
  .skill-es6::after {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}

@media (max-width: 1450px) {
  .skill-es6 {
    margin-top: 40px;
    -ms-grid-column: 7;
    grid-column: 7;
    -ms-grid-row: 4;
    grid-row: 4;
    justify-self: flex-start;
  }
}

@media (max-width: 800px) {
  .skill-es6 {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 3;
    grid-row: 3;
    -ms-grid-column-align: center;
        justify-self: center;
  }
}

.skill-react {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 6;
  grid-row: 6;
  justify-self: flex-start;
}

.skill-react::after {
  content: "React";
  position: absolute;
  display: block;
  top: 20px;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}

@media (max-width: 800px) {
  .skill-react::after {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}

@media (max-width: 800px) {
  .skill-react {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 2;
    grid-row: 2;
    justify-self: flex-start;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}

.skill-css {
  -ms-grid-column: 7;
  grid-column: 7;
  -ms-grid-row: 6;
  grid-row: 6;
  -ms-grid-column-align: -10deg;
      justify-self: -10deg;
}

.skill-css::after {
  content: "CSS";
  position: absolute;
  display: block;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}

@media (max-width: 800px) {
  .skill-css::after {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
}

@media (max-width: 1450px) {
  .skill-css {
    -ms-grid-column: 7;
    grid-column: 7;
    -ms-grid-row: 6;
    grid-row: 6;
    justify-self: flex-start;
  }
}

@media (max-width: 800px) {
  .skill-css {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 3;
    grid-row: 3;
    justify-self: flex-start;
  }
}

.skill-sass {
  -ms-grid-column: 7;
  grid-column: 7;
  -ms-grid-row: 8;
  grid-row: 8;
  justify-self: flex-start;
}

.skill-sass::after {
  content: "SCSS";
  position: absolute;
  display: block;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

@media (max-width: 800px) {
  .skill-sass::after {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}

@media (max-width: 800px) {
  .skill-sass {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 4;
    grid-row: 4;
    justify-self: flex-start;
  }
}

.skill-xd {
  -ms-grid-column: 4;
  grid-column: 4;
  -ms-grid-row: 10;
  grid-row: 10;
  justify-self: flex-start;
}

.skill-xd::after {
  content: "Adobe Xd";
  position: absolute;
  display: block;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}

@media (max-width: 800px) {
  .skill-xd::after {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}

@media (max-width: 800px) {
  .skill-xd {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 5;
    grid-row: 5;
    justify-self: flex-start;
  }
}

.skill-photoshop {
  -ms-grid-column: 6;
  grid-column: 6;
  -ms-grid-row: 10;
  grid-row: 10;
  justify-self: flex-start;
}

.skill-photoshop::after {
  content: "Photoshop";
  position: absolute;
  display: block;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

@media (max-width: 800px) {
  .skill-photoshop::after {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
}

@media (max-width: 1450px) {
  .skill-photoshop {
    left: -150px;
  }
}

@media (max-width: 800px) {
  .skill-photoshop {
    left: 0;
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 4;
    grid-row: 4;
    justify-self: flex-start;
  }
}

.curly-bracket {
  height: 70px;
}

@media (max-width: 500px) {
  .curly-bracket {
    height: 55px;
  }
}

.open-curly-bracket {
  margin-top: 20px;
  margin-left: 20px;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  justify-self: flex-start;
}

@media (max-width: 800px) {
  .open-curly-bracket {
    margin-left: 0px;
    -ms-grid-row: 2;
    grid-row: 2;
  }
}

.close-curly-bracket {
  margin-right: 20px;
  -ms-grid-column: 9;
  grid-column: 9;
  -ms-grid-row: 10;
  grid-row: 10;
  justify-self: flex-end;
}

@media (max-width: 800px) {
  .close-curly-bracket {
    -ms-grid-column: 5;
    grid-column: 5;
    -ms-grid-row: 5;
    grid-row: 5;
    justify-self: flex-start;
  }
}

.dash-1 {
  margin-bottom: -100px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-grid-row: 10;
  grid-row: 10;
  height: 200px;
  width: 10px;
}

@media (max-width: 800px) {
  .dash-1 {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 5;
    grid-row: 5;
    height: 150px;
    margin-bottom: -75px;
  }
}
