@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #546663;
  font-family: 'Roboto', sans-serif;
}

.main-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1400px 1fr;
      grid-template-columns: 1fr 1400px 1fr;
}

@media (max-width: 1450px) {
  .main-container {
    -ms-grid-columns: 1fr 800px 1fr;
        grid-template-columns: 1fr 800px 1fr;
  }
}

@media (max-width: 800px) {
  .main-container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.dash {
  background-color: #5AE6CE;
  -ms-grid-column-align: center;
      justify-self: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.about-section {
  -ms-grid-column: 2;
  grid-column: 2;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 10fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 10fr 1fr 1fr;
  color: #00AD90;
  font-weight: 400;
  min-height: 100vh;
}

.about-name {
  margin-top: 120px;
  width: 400px;
  height: 200px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -ms-grid-column-align: center;
      justify-self: center;
  text-align: center;
  -ms-grid-column: 3;
  grid-column: 3;
  font-size: 60px;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.about-name .about-name-light {
  font-weight: 500;
  font-size: 80px;
  color: #00E0BB;
}

@media (max-width: 1450px) {
  .about-name {
    font-size: 50px;
    height: 170px;
  }
  .about-name .about-name-light {
    font-size: 70px;
  }
}

@media (max-width: 800px) {
  .about-name {
    font-size: 40px;
    height: 130px;
    margin-top: 80px;
  }
  .about-name .about-name-light {
    font-size: 60px;
  }
}

@media (max-width: 500px) {
  .about-name {
    font-size: 33px;
    width: 100%;
    height: 110px;
  }
  .about-name .about-name-light {
    font-size: 45px;
  }
}

.about-desc {
  -ms-grid-column: 3;
  grid-column: 3;
  color: #00E0BB;
  font-size: 40px;
  text-align: center;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 100px;
}

@media (max-width: 1450px) {
  .about-desc {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  .about-desc .about-desc-separator {
    visibility: hidden;
  }
  .about-desc .about-desc-separator::before {
    content: "\a";
    white-space: pre;
  }
  .about-desc .about-desc-separator:first-child {
    margin-left: -20px;
  }
}

@media (max-width: 500px) {
  .about-desc {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 45px;
  }
}

.about-check {
  -ms-grid-column: 3;
  grid-column: 3;
  color: #5AE6CE;
  font-weight: 500;
  font-size: 90px;
  text-align: center;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}

@media (max-width: 1450px) {
  .about-check {
    font-size: 70px;
  }
}

@media (max-width: 800px) {
  .about-check {
    font-size: 55px;
  }
}

@media (max-width: 500px) {
  .about-check {
    font-size: 40px;
  }
}

.about-arrow {
  -ms-grid-column: 3;
  grid-column: 3;
  -ms-grid-column-align: center;
      justify-self: center;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
}

@media (max-width: 1450px) {
  .about-arrow {
    height: 130px;
  }
}

@media (max-width: 800px) {
  .about-arrow {
    margin-top: 0px;
    margin-bottom: 10px;
    height: 100px;
  }
}

.dash-2 {
  -ms-grid-column: 3;
  grid-column: 3;
  width: 400px;
  height: 10px;
}

@media (max-width: 800px) {
  .dash-2 {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .dash-2 {
    width: 220px;
  }
}

.dash-vertical {
  width: 10px;
  height: 200px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.dash-3 {
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-flex-item-align: end;
      align-self: flex-end;
  height: 200px;
}

@media (max-width: 500px) {
  .dash-3 {
    height: 150px;
  }
}

.dash-4 {
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  height: 150px;
}

@media (max-width: 500px) {
  .dash-4 {
    height: 120px;
  }
}

.dash-5 {
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 2;
  grid-column: 2;
}

@media (max-width: 500px) {
  .dash-5 {
    height: 100px;
  }
}

.dash-6 {
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 4;
  grid-column: 4;
  height: 150px;
  margin-top: 20px;
}

@media (max-width: 500px) {
  .dash-6 {
    height: 100px;
  }
}

.dash-7 {
  -ms-grid-row: 5;
  grid-row: 5;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-flex-item-align: end;
      align-self: flex-end;
  height: 300px;
  margin-bottom: -150px;
}

@media (max-width: 500px) {
  .dash-7 {
    height: 200px;
    margin-bottom: -100px;
  }
}

.dash-8 {
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-flex-item-align: end;
      align-self: flex-end;
  height: 250px;
  margin-bottom: -80px;
}

@media (max-width: 500px) {
  .dash-8 {
    height: 150px;
    margin-bottom: -60px;
  }
}
