@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #546663;
  font-family: 'Roboto', sans-serif;
}

.main-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1400px 1fr;
      grid-template-columns: 1fr 1400px 1fr;
}

@media (max-width: 1450px) {
  .main-container {
    -ms-grid-columns: 1fr 800px 1fr;
        grid-template-columns: 1fr 800px 1fr;
  }
}

@media (max-width: 800px) {
  .main-container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

.dash {
  background-color: #5AE6CE;
  -ms-grid-column-align: center;
      justify-self: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.project-wrapper {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1/4;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1400px 1fr;
      grid-template-columns: 1fr 1400px 1fr;
  min-height: 100vh;
  position: relative;
}

@media (max-width: 1450px) {
  .project-wrapper {
    -ms-grid-columns: 1fr 800px 1fr;
        grid-template-columns: 1fr 800px 1fr;
  }
}

@media (max-width: 800px) {
  .project-wrapper {
    -ms-grid-columns: 1fr 5fr 1fr;
        grid-template-columns: 1fr 5fr 1fr;
  }
}

@media (max-width: 500px) {
  .project-wrapper {
    -ms-grid-columns: 1fr 10fr 1fr;
        grid-template-columns: 1fr 10fr 1fr;
  }
}

.project-section {
  -ms-grid-column: 2;
  grid-column: 2;
  display: -ms-grid;
  display: grid;
  margin-top: 120px;
  -ms-grid-columns: 1fr 5fr 1fr;
      grid-template-columns: 1fr 5fr 1fr;
}

@media (max-width: 500px) {
  .project-section {
    -ms-grid-columns: 1fr 10fr 1fr;
        grid-template-columns: 1fr 10fr 1fr;
  }
}

a {
  text-decoration: none;
}

.project-gallery {
  -ms-grid-column: 2;
  grid-column: 2;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 400px 400px;
      grid-template-columns: 400px 400px;
  -ms-grid-column-align: center;
      justify-self: center;
  gap: 30px;
  margin-top: 30px;
  color: #006151;
}

.project-gallery .project-gallery-item {
  position: relative;
  background-color: #5AE6CE;
  border-radius: 15px;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  padding: 30px;
}

.project-gallery .project-gallery-item .gallery-item-image {
  width: calc(25% - 10px);
  margin: 5px;
  opacity: 0.3;
}

.project-gallery .project-gallery-item .gallery-item-text {
  color: #006151;
  font-size: 40px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

@media (max-width: 1450px) {
  .project-gallery .project-gallery-item .gallery-item-text {
    font-size: 28px;
  }
}

@media (max-width: 500px) {
  .project-gallery .project-gallery-item .gallery-item-text {
    font-size: 20px;
  }
}

@media (max-width: 1450px) {
  .project-gallery {
    -ms-grid-columns: 280px 280px;
        grid-template-columns: 280px 280px;
  }
}

@media (max-width: 800px) {
  .project-gallery {
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
  }
  .project-gallery .gallery-item-text {
    margin: 25px;
  }
}

.project-braces {
  position: relative;
  z-index: 1;
  color: #5AE6CE;
  font-weight: 300;
  font-size: 120px;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 800px) {
  .project-braces {
    font-size: 100px;
  }
}

.project-braces-open {
  -ms-grid-column: 1;
  grid-column: 1;
  justify-self: flex-end;
}

@media (max-width: 1450px) {
  .project-braces-open {
    -ms-grid-column-align: center;
        justify-self: center;
  }
}

@media (max-width: 800px) {
  .project-braces-open {
    left: -20px;
    top: 0px;
  }
}

.project-braces-close {
  -ms-grid-column: 3;
  grid-column: 3;
  -ms-grid-row: 2;
  grid-row: 2;
}

@media (max-width: 1450px) {
  .project-braces-close {
    -ms-grid-column-align: center;
        justify-self: center;
  }
}

@media (max-width: 800px) {
  .project-braces-close {
    right: -20px;
    bottom: 100px;
  }
}

.project-triangle {
  height: 500px;
}

@media (max-width: 1450px) {
  .project-triangle {
    height: 400px;
  }
}

@media (max-width: 500px) {
  .project-triangle {
    height: 230px;
  }
}

.project-triangle-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.project-triangle-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.active-image {
  opacity: 1 !important;
}
