@import './main.Scss';

.project-wrapper{
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 1fr 1400px 1fr;
    min-height: 100vh;
    position: relative;
    @media (max-width: $medium) {
        grid-template-columns: 1fr 800px 1fr;        
    }
    @media (max-width: $small) {
        grid-template-columns: 1fr 5fr 1fr;        
    }
    @media (max-width: $smartphone) {
        grid-template-columns: 1fr 10fr 1fr;        
    }
}

.project-section{
    grid-column: 2;
    display: grid;
    margin-top: 120px;
    grid-template-columns: 1fr 5fr 1fr;
    @media (max-width: $smartphone) {
        grid-template-columns: 1fr 10fr 1fr;        
    }
}

a{
    text-decoration: none;
}
.project-gallery{
    grid-column: 2;
    display: grid;
    grid-template-columns: 400px 400px;
    justify-self: center;
    gap: 30px;
    margin-top: 30px;
    color: $background;
    .project-gallery-item{
        position: relative;
        background-color: $light;
        border-radius: 15px;
        box-shadow: 3px 3px 6px rgba(0,0,0,0.3);
        padding: 30px;
        .gallery-item-image{
            width: calc(25% - 10px);
            margin: 5px;
            opacity: 0.3;
        }
        .gallery-item-text{
            color: $background;
            font-size: 40px;
            font-weight: 500;
            margin: 0px;
            padding: 0px;
            text-align: center;
            @media (max-width: $medium){
                font-size: 28px;
            }
            @media (max-width: $smartphone) {
                font-size: 20px;
            }
        }
    }
    @media (max-width: $medium) {
        grid-template-columns: 280px 280px;       
    }  
    @media (max-width: $small) {
        grid-template-columns: 100%;
        .gallery-item-text{
            margin: 25px;
        }       
    }  
}

.project-braces{
    position: relative;
    z-index: 1;
    color: $light;
    font-weight: 300;
    font-size: 120px;
    text-shadow: 0px 3px 6px rgba(0,0,0,0.3);
    user-select: none;
    @media (max-width: $small) {
        font-size: 100px;        
    }
}

.project-braces-open{
    grid-column: 1;
    justify-self: flex-end;
    @media (max-width: $medium) {
        justify-self: center;
    }
    @media (max-width: $small) {
        left: -20px;
        top: 0px;
    }   
}

.project-braces-close{
    grid-column: 3;
    grid-row: 2;
    @media (max-width: $medium) {
        justify-self: center;   
    }
    
    @media (max-width: $small) {
        right: -20px;
        bottom:100px;
    }   
}

.project-triangle{
    height: 500px;
    @media (max-width: $medium) {
        height: 400px;
    }   
    @media (max-width: $smartphone) {
        height: 230px;
    }   
}

.project-triangle-left{
    position: absolute;
    left:0;
    bottom: 0;
}

.project-triangle-right{
    position: absolute;
    right: 0;
    bottom: 0;
}

.active-image{
    opacity: 1 !important;
}