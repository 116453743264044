@import "./main.Scss";

.landing-wrapper{
    background-color: $primary;
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 1fr 1400px 1fr;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
    min-height: 750px;
    @media (max-width: $medium) {
        grid-template-columns: 1fr 800px 1fr;
    }
    @media (max-width: $small) {
        min-height: 600px;
        grid-template-columns: 1fr 500px 1fr;
    }
    @media (max-width: $smartphone){
        grid-template-columns: 1fr 300px 1fr;
    }
}

.landing-section{
    grid-column: 2;
    display: grid;
    grid-template-columns: repeat(4,100px) 600px repeat(4,100px);
    width: 100%;
    min-height: 100vh;
    @media (max-width: $medium) {
        grid-template-columns: repeat(4, 50px) 400px repeat(4, 50px);
    }
    @media (max-width: $small){
        grid-template-rows: 320px;
        grid-template-columns: 20px 1fr 2fr 1fr 20px;
        gap: 10px;
    }
    @media (max-width: $smartphone){
        grid-template-columns: 10px 1fr 1.5fr 1fr 10px;
        gap: 5px;   
    }
}

.icon-bar{
    position: absolute;
    right: 20px;
    top: 30px;
    height: 50px;
    z-index: 3;
    img{
        height: 100%;
        margin-right: 10px;
    }
    @media (max-width: $small) {
        height: 40px;
        img{
            margin-right: 8px;
        }
    }
}

.logo{
    @include placeItem(5,5,center);
    position: relative;
    align-self: center;
    .logo-back{
        position: absolute;
        top:-110px;
        left: -20px;
        z-index: 1;
        width: 100%;
    }
    .logo-front{
        position: relative;
        z-index: 2;
        width: 100%;
    }
    &::before{
        content: '<webdev />';
        position: absolute;
        top: 160px;
        color: $background;
        font-size: 110px;
        font-weight: 300;
        text-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    }
    @media (max-width: $medium) {
        .logo-back{
            top:-75px;
        }
        &::before{
            font-size: 79px;
            top: 120px;
        }
    }
    @media (max-width: $small) {
        margin-top: 120px;
        grid-row: 1;
        grid-column: 1/6;
        width: 300px;
        .logo-back{
            top: -60px;
        }
        &::before{
            top: 85px;
            font-size: 59px;
        }
    }
    @media (max-width: $smartphone){
        width: 280px;
        .logo-back{
            top:-50px;
        }
        &::before{
            top: 70px;
            font-size: 55px;
        }
    }
}

.skill{
    position: relative;
    color: $light;
    font-size: 45px;
    font-weight: 300;
    letter-spacing: 5px;
    text-shadow: 0px 3px 6px rgba(0,0,0,0.2);
    transform-origin:center;
    user-select: none;
    z-index: 4;
    @media (max-width: $medium) {
        font-size: 40px;        
    }
    @media (max-width: $small) {
        font-size: 30px;        
    }
    @media (max-width: $smartphone){
        font-size: 22px;
        letter-spacing: 2px;
    }
}

.skill-html{
    &::after{
        content: "HTML";
        position: absolute;
        display: block;
        transform: rotate(-5deg);
        @media (max-width: $small){
            transform: rotate(8deg);
        }
    }
    @include placeItem(4,1);
    align-self:flex-end;
    @media (max-width: $small) {
        @include placeItem(1,2,flex-end);
        left: 15px;
        align-self: center;
    }
}

.skill-js{
    &::after{
        content: "JavaScript";
        position: absolute;
        display: block;
        top: -50px;
        transform: rotate(10deg);
        @media (max-width: $small){
            transform: rotate(17deg);
        }
    }
    @include placeItem(5,1,center);
    align-self: flex-end;
    @media (max-width: $small) {
        @include placeItem(3,3);
        align-self: center;
    }
}

.skill-git{
    &::after{
        content: "GIT";
        position: absolute;
        display: block;
        transform: rotate(-10deg);
        @media (max-width: $small){
            transform: rotate(-25deg);
        }
    }
    @include placeItem(9,2);
    @media (max-width: $medium) {
        @include placeItem(7,2);
    }
    @media (max-width: $small) {
        @include placeItem(1,5,flex-end);
        align-self: flex-start;
        top:15px;
    }
}

.skill-gsap{
    &::after{
        content: "GSAP";
        position: absolute;
        display: block;
        transform: rotate(-20deg);
        @media (max-width: $small){
            transform: rotate(-10deg);
        }
    }
    @include placeItem(2,4);
    @media (max-width: $small) {
        @include placeItem(4,2);
        align-self: center;
    }
}

.skill-es6{
    &::after{
        content: "ES6";
        position: absolute;
        display: block;
        transform: rotate(10deg);
        @media (max-width: $small){
            transform: rotate(-20deg);
        }
    }
    @include placeItem(8,4,10deg);
    @media (max-width: $medium) {
        margin-top: 40px;
        @include placeItem(7,4);
    }
    @media (max-width: $small) {
        @include placeItem(4,3,center);
    }
}

.skill-react{
    &::after{
        content: "React";
        position: absolute;
        display: block;
        top:20px;
        transform: rotate(10deg);
        @media (max-width: $small){
            transform: rotate(-15deg);
        }
    }
    @include placeItem(2,6);
    @media (max-width: $small) {
        @include placeItem(3,2,flex-start);
        align-self: flex-start;
    }
}

.skill-css{
    &::after{
        content: "CSS";
        position: absolute;
        display: block;
        transform: rotate(-10deg);
        @media (max-width: $small){
            transform: rotate(-5deg);
        }
    }
    @include placeItem(7,6,-10deg);
    @media (max-width: $medium) {
        @include placeItem(7,6);
    }
    @media (max-width: $small) {
        @include placeItem(2,3);
    }
}

.skill-sass{
    &::after{
        content: "SCSS";
        position: absolute;
        display: block;
        transform: rotate(-20deg);
        @media (max-width: $small){
            transform: rotate(15deg);
        }
    }
    @include placeItem(7,8);
    @media (max-width: $small) {
        @include placeItem(2,4);
    }
}

.skill-xd{
    &::after{
        content: "Adobe Xd";
        position: absolute;
        display: block;
        transform: rotate(-10deg);
        @media (max-width: $small){
            transform: rotate(8deg);
        }
    }
    @include placeItem(4,10);
    @media (max-width: $small) {
        @include placeItem(4,5);
    }
}

.skill-photoshop{
    &::after{
        content: "Photoshop";
        position: absolute;
        display: block;
        transform: rotate(15deg);
        @media (max-width: $small){
            transform: rotate(3deg);
        }
    }
    @include placeItem(6,10,flex-start);
    @media (max-width: $medium) {
        left: -150px;
    }
    @media (max-width: $small) {
        left:0;
        @include placeItem(3,4);
    }
}

.curly-bracket{
    height: 70px;
    @media (max-width: $smartphone){
     height: 55px;
    }
}

.open-curly-bracket{
    margin-top: 20px;
    margin-left: 20px;
    @include placeItem(1,1);
    @media (max-width: $small){
        margin-left: 0px;
        grid-row: 2;
    }
}

.close-curly-bracket{
    margin-right: 20px;
    @include placeItem(9,10,flex-end);
    @media (max-width: $small) {
        @include placeItem(5,5);
    }
}

.dash-1{
    margin-bottom: -100px;
    align-self:flex-end;
    grid-column: 5;
    grid-row: 10;
    height: 200px;
    width: 10px;
    @media (max-width: $small) {
        grid-column: 3;
        grid-row: 5;
        height: 150px;
        margin-bottom: -75px;
    }
}